<template>
  <div class="result-box" v-if="pending !== 1">
    <div class="process-box">
      <el-progress class="process-item" type="circle" :percentage="percent" :show-text="false"></el-progress>
      <img class="wallet" src="@/assets/images/wallet.png" width="60" />
    </div>
    <div class="margin-top-28 pending">
      <span>{{ $t('message.result.processing') }}</span>
      <span class="ellipsis">..........</span>
    </div>
  </div>
</template>
<script>
import { getStatus, getBrandInfo } from '@/api/checkout';
import { getCheckoutType, sendGaResultEvent } from '@/utils/tools/ga';
import { exceptionShow } from '@/utils/tools/utils';

export default {
  data() {
    return {
      percent: 10,
      tryCount: 0,
      tryInterval: 1000,
      pending: 1,
      type: '',
      orderInfo: null,
    };
  },
  created() {
    this.type = getCheckoutType(this.$route.query.type, this.$route.query.key);
    getBrandInfo(this.$route.query.key).then((res) => {
      if (res.respCode === '20000' && res.data) {
        this.$store.dispatch('app/setBrandInfo', res.data);
      }
    }).catch(() => ({}));
    const status = this.$route.query.status;
    // s0状态下, 重试次数为1, 重试间隔为1s
    if (status !== 's0') {
      this.pending = 16;
    }
    this.getOrderStatus();
    window.addEventListener('beforeunload', this.beforeUnloadEvent);
  },
  beforeDestroy() {
    this.sendGaEvent('exit_payment_result_page');
    window.removeEventListener('beforeunload', this.beforeUnloadEvent);
  },
  methods: {
    getOrderStatus() {
      this.tryCount += 1;
      getStatus(this.$route.query.key ? this.$route.query.key : this.$route.params.id).then(res => {
        if (res.respCode === '20000') {
          this.orderInfo = res.data;
          if (res.data.status === 'S') { // 成功
            // 换卡成功, 跳转到页面
            if (res.data.subscriptionManageUrl) {
              // 跳转
              window.location.href = res.data.subscriptionManageUrl;
            } else {
              this.$router.push({
                path: '/result/success',
                query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
              });
            }
          } else if (res.data.status === 'F') { // 失败
            if (res.data.subscriptionManageUrl) {
              return this.$message(`${this.$t('message.checkout.updateFailed')}`);
            } else {
              this.$router.push({
                path: '/result/failed',
                query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
              });
            }
          } else if (res.data.status === 'P' || res.data.status === 'R') { // 等待/重定向
            if (this.tryCount < this.pending) { // 重试
              setTimeout(() => {
                this.getOrderStatus();
              }, this.tryInterval);
            } else {
              if (res.data.status === 'P' || !res.data.redirectUrl) {
                this.$router.push({
                  path: '/result/unknow',
                  query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
                });
              } else {
                window.location.href = res.data.redirectUrl;
              }
            }
          } else {
            this.$router.push({
              path: '/result/failed',
              query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
            });
          }
        } else {
          // 超时订单处理
          if (res.respCode === '00020|50054' || res.respCode === '50054') {
            if (this.tryCount < this.pending) {
              setTimeout(() => {
                this.getOrderStatus();
              }, this.tryInterval);
            } else {
              this.$router.push({
                path: '/result/unknow',
                query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
              });
            }
            return;
          }
          if (exceptionShow(res.respCode, this.$router, this.$route.query.key ? this.$route.query.key : this.$route.params.id, this.type)) {
            return;
          }
          this.$router.push({
            path: '/result/failed',
            query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
          });
        }
        if (this.tryCount === 1) {
          this.sendGaEvent('visit_payment_result_page');
        }
      }).catch(() => {
        if (this.tryCount === 1) {
          this.sendGaEvent('visit_payment_result_page');
        }
        if (this.tryCount < this.pending) { // 重试
          setTimeout(() => {
            this.getOrderStatus();
          }, this.tryInterval);
        } else {
          this.$router.push({
            path: '/result/unknow',
            query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
          });
        }
      });
    },
    sendGaEvent(eventName) {
      sendGaResultEvent(this.$route.query.type, this.$route.query.key, this.orderInfo, eventName);
    },
    beforeUnloadEvent() {
      this.sendGaEvent('exit_payment_result_page');
    },
  },
};
</script>

<style scoped lang="scss">
.process-box {
  position: relative;
  display: inline-block;

  .process-item {
    animation: 1.5s linear 0s normal none infinite rotate;
  }

  .wallet {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.ellipsis {
  position: absolute;
}
</style>
